import * as Papa from "papaparse";

import {
  Form,
  GraphContainer,
  LeftMenu,
  ListItem,
  OtherList,
  Question,
  ResultsContainer,
} from "../styles/results";
import { Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
//  @ts-ignore
import { CSVLink } from "react-csv";
import CSVReader from "react-csv-reader";
import { Layout } from "../components/Layout";
import MenuItem from "@mui/material/MenuItem";
import { PageProps } from "gatsby";
import Select from "@mui/material/Select";
import { questionsBroker } from "../questionsBroker";
import { questionsBrokerLong } from "questionsBrokerLong";
import { questionsEmployer } from "../questionsEmployer";
import { questionsEmployerLong } from "questionsEmployerLong";

type DataProps = unknown;

const Dif = "—";

const RADIAN = Math.PI / 180;

const ResultsPage: React.FC<PageProps<DataProps>> = () => {
  const [data, setData] = useState([]);
  const [dataParsed, setDataParsed] = useState<any>([]);
  const [objectData, setObjectData] = useState([]);
  const [inputValue, setInputValue] = useState("q1");
  const [role, setRole] = useState("employer");
  const [stats, setStats] = useState<any>(null);
  const [statsQuestion, setStatsQuestion] = useState<any>("");
  const [otherResponses, setOtherResponses] = useState<any>([]);
  const [formVersion, setFormVersion] = useState<string>("shortVersion");
  const [myColumns, setMyColumns] = useState<any>([]);

  const columns: any = [];
  const [string, setString] = useState<any>();

  const resultsParse = (results: any[]) => {
    results.shift();
    results.pop();
    setRole(results[0][7]);
    const parsedResults = results.map((response: any, i: number) => {
      let result: string = response[8] || "";
      i === 7 && setString(result);
      return result;
    });
    const response = parsedResults.map((result: any) => {
      return JSON.parse(result);
    });

    return response;
  };

  const fetchCsv = async () => {
    return fetch("/csv.csv").then(function (response) {
      let reader = response.body.getReader();
      let decoder = new TextDecoder("utf-8");

      return reader.read().then(function (result) {
        return decoder.decode(result.value);
      });
    });
  };

  const saveData = (result: any) => {
    setObjectData(result.data);
    const data = resultsParse(result.data);
    setData(data);
  };

  const getCsvData = async () => {
    let csvData = await fetchCsv();

    Papa.parse(csvData, {
      complete: saveData,
    });
  };

  const handleFileUpload = async (uploadedData: any) => {
    let csvData = await fetchCsv();

    Papa.parse(uploadedData, {
      complete: saveData,
    });
  };

  const employerColumns = [
    { key: "q1", name: "Q1", label: "Q1" },
    { key: "q1_other", name: "Q1_other", label: "Q1_other" },
    { key: "q2", name: "Q2", label: "Q2" },
    { key: "q2_other", name: "Q2_other", label: "Q2_other" },
    { key: "q3", name: "Q3", label: "Q3" },
    { key: "q3_other", name: "Q3_other", label: "Q3_other" },
    { key: "q4", name: "Q4", label: "Q4" },
    { key: "q4_other", name: "Q4_other", label: "Q4_other" },
    { key: "q5", name: "Q5", label: "Q5" },
    { key: "q5_other", name: "Q5_other", label: "Q5_other" },
    { key: "q6", name: "Q6", label: "Q6" },
    { key: "q6_other", name: "Q6_other", label: "Q6_other" },
    { key: "q7", name: "Q7", label: "Q7" },
    { key: "q7_other", name: "Q7_other", label: "Q7_other" },
    { key: "q8", name: "Q8", label: "Q8" },
    { key: "q8_other", name: "Q8_other", label: "Q8_other" },
    { key: "q9", name: "Q9", label: "Q9" },
    { key: "q9_other", name: "Q9_other", label: "Q9_other" },
    { key: "q10", name: "Q10", label: "Q10" },
    { key: "q10_other", name: "Q10_other", label: "Q10_other" },
    { key: "q11", name: "Q11", label: "Q11" },
    { key: "q11_other", name: "Q11_other", label: "Q11_other" },
    { key: "q12", name: "Q12", label: "Q12" },
    { key: "q12_other", name: "Q12_other", label: "Q12_other" },
    { key: "q13", name: "Q13", label: "Q13" },
    { key: "q13_other", name: "Q13_other", label: "Q13_other" },
    { key: "q14", name: "Q14", label: "Q14" },
    { key: "q14_other", name: "Q14_other", label: "Q14_other" },
    { key: "q15", name: "Q15", label: "Q15" },
    { key: "q15_other", name: "Q15_other", label: "Q15_other" },
    { key: "q16", name: "Q16", label: "Q16" },
    { key: "q16_other", name: "Q16_other", label: "Q16_other" },
    { key: "q17", name: "Q17", label: "Q17" },
    { key: "q17_other", name: "Q17_other", label: "Q17_other" },
    { key: "q18", name: "Q18", label: "Q18" },
    { key: "q18_other", name: "Q18_other", label: "Q18_other" },
    { key: "q19", name: "Q19", label: "Q19" },
    { key: "q19_other", name: "Q19_other", label: "Q19_other" },
    { key: "q20", name: "Q20", label: "Q20" },
    { key: "q20_other", name: "Q20_other", label: "Q20_other" },
    { key: "q21", name: "Q21", label: "Q21" },
    { key: "q21_other", name: "Q21_other", label: "Q21_other" },
    { key: "userEmail", name: "Email", label: "Email" },
    { key: "name", name: "Name", label: "Name" },
    { key: "firmName", name: "Firm Name", label: "Firm Name" },
    { key: "q22_company", name: "Company/Client Size", label: "Company/Client Size" },
    { key: "q22_state", name: "State", label: "State" },
  ];
  const brokerColumns = [
    { key: "q1", name: "Q1", label: "Q1" },
    { key: "q1_other", name: "Q1_other", label: "Q1_other" },
    { key: "q2", name: "Q2", label: "Q2" },
    { key: "q2_other", name: "Q2_other", label: "Q2_other" },
    { key: "q3", name: "Q3", label: "Q3" },
    { key: "q3_other", name: "Q3_other", label: "Q3_other" },
    { key: "q4", name: "Q4", label: "Q4" },
    { key: "q4_other", name: "Q4_other", label: "Q4_other" },
    { key: "q5", name: "Q5", label: "Q5" },
    { key: "q5_other", name: "Q5_other", label: "Q5_other" },
    { key: "q6", name: "Q6", label: "Q6" },
    { key: "q6_other", name: "Q6_other", label: "Q6_other" },
    { key: "q7", name: "Q7", label: "Q7" },
    { key: "q7_other", name: "Q7_other", label: "Q7_other" },
    { key: "q8", name: "Q8", label: "Q8" },
    { key: "q8_other", name: "Q8_other", label: "Q8_other" },
    { key: "q9", name: "Q9", label: "Q9" },
    { key: "q9_other", name: "Q9_other", label: "Q9_other" },
    { key: "q10", name: "Q10", label: "Q10" },
    { key: "q10_other", name: "Q10_other", label: "Q10_other" },
    { key: "q11", name: "Q11", label: "Q11" },
    { key: "q11_other", name: "Q11_other", label: "Q11_other" },
    { key: "q12", name: "Q12", label: "Q12" },
    { key: "q12_other", name: "Q12_other", label: "Q12_other" },
    { key: "q13", name: "Q13", label: "Q13" },
    { key: "q13_other", name: "Q13_other", label: "Q13_other" },
    { key: "q14", name: "Q14", label: "Q14" },
    { key: "q14_other", name: "Q14_other", label: "Q14_other" },
    { key: "q15", name: "Q15", label: "Q15" },
    { key: "q15_other", name: "Q15_other", label: "Q15_other" },
    { key: "q16", name: "Q16", label: "Q16" },
    { key: "q16_other", name: "Q16_other", label: "Q16_other" },
    { key: "q17", name: "Q17", label: "Q17" },
    { key: "q17_other", name: "Q17_other", label: "Q17_other" },
    { key: "q18", name: "Q18", label: "Q18" },
    { key: "q18_other", name: "Q18_other", label: "Q18_other" },
    { key: "q19", name: "Q19", label: "Q19" },
    { key: "q19_other", name: "Q19_other", label: "Q19_other" },
    { key: "q20", name: "Q20", label: "Q20" },
    { key: "q20_other", name: "Q20_other", label: "Q20_other" },
    { key: "q21", name: "Q21", label: "Q21" },
    { key: "q21_other", name: "Q21_other", label: "Q21_other" },
    { key: "q22", name: "Q22", label: "Q22" },
    { key: "q22_other", name: "Q22_other", label: "Q22_other" },
    { key: "userEmail", name: "Email", label: "Email" },
    { key: "name", name: "Name", label: "Name" },
    { key: "firmName", name: "Firm Name", label: "Firm Name" },
    { key: "q23_company", name: "Company/Client Size", label: "Company/Client Size" },
    { key: "q23_state", name: "State", label: "State" },
  ];

  const csvReport = {
    data: dataParsed,
    headers: myColumns,
    filename: "report.csv",
  };

  useEffect(() => {
    const newDataParsed = data.filter((e: any) => {
      if (formVersion === "shortVersion") {
        return Object.keys(e).length < 25;
      } else {
        return Object.keys(e).length > 25;
      }
    });
    setDataParsed(data);
    setMyColumns(
      role === "employer"
          ? employerColumns
          : brokerColumns
    );
  }, [data, formVersion, role]);

  const splitMyResults = () => {
    let newStats: any = [];
    let other: any = [];
    const getMyResults = (response: any) => {
      let userResponse = response[inputValue];
      let userOther = response[inputValue + "_other"];
      if (userOther) {
        other.push(userOther);
      }
      if (userResponse) {
        let separatedResponses = userResponse.split("—");
        separatedResponses.map((singleResponse: any) => {
          if (
            newStats.filter((e: any) => e.name === singleResponse).length > 0
          ) {
            let object = newStats.filter(
              (e: any) => e.name === singleResponse
            )[0];
            let newObject = {
              name: singleResponse,
              value: (object?.value || 0) + 1,
            };
            newStats[newStats.indexOf(object)] = newObject;
          } else {
            singleResponse !== "" &&
              newStats.push({ name: singleResponse, value: 1 });
          }
        });
      }
    };
    data.map((response: any) => {
      getMyResults(response);
    });
    setStats(newStats);
    setStatsQuestion(
      formVersion === "shortVersion"
        ? role === "employer"
          ? questionsEmployer.filter((q: any) => q.uid === inputValue)[0][
              "question"
            ]
          : questionsBroker.filter((q: any) => q.uid === inputValue)[0][
              "question"
            ]
        : role === "employer"
        ? questionsEmployerLong.filter((q: any) => q.uid === inputValue)[0][
            "question"
          ]
        : questionsBrokerLong.filter((q: any) => q.uid === inputValue)[0][
            "question"
          ]
    );
    setOtherResponses(other);
  };

  return (
    <Layout>
      {data.length === 0 && (
        <Form>
          <h2>1. Upload your raw CSV</h2>
          <CSVReader
            cssInputClass="custom-file-input"
            onFileLoaded={(data, fileInfo, originalFile) =>
              handleFileUpload(originalFile)
            }
          />
        </Form>
      )}
      {data.length > 0 && (
        <>
          <LeftMenu>
            <CSVLink {...csvReport}>
              <Button variant="contained">Export parsed CSV</Button>
            </CSVLink>
          </LeftMenu>
          
          {
          
          /* <Form>
            <h2>1. What form version you want?</h2>
            <Select
              style={{ margin: "30px 0" }}
              fullWidth
              value={formVersion}
              onChange={(e) => setFormVersion(e.target.value)}
            >
              <MenuItem value={"shortVersion"}>Short Version</MenuItem>
              <MenuItem value={"longVersion"}>Long Version</MenuItem>
            </Select>
            <h2>2. Select the Question you want to get Stats</h2>
            {formVersion === "shortVersion" ? (
              role === "employer" ? (
                <Select
                  style={{ margin: "30px 0" }}
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                >
                  <MenuItem value={"q1"}>Question 1</MenuItem>
                  <MenuItem value={"q2"}>Question 2</MenuItem>
                  <MenuItem value={"q3"}>Question 3</MenuItem>
                  <MenuItem value={"q4"}>Question 4</MenuItem>
                  <MenuItem value={"q5"}>Question 5</MenuItem>
                  <MenuItem value={"q6"}>Question 6</MenuItem>
                  <MenuItem value={"q7"}>Question 7</MenuItem>
                  <MenuItem value={"q8"}>Question 8</MenuItem>
                  <MenuItem value={"q9"}>Question 9</MenuItem>
                  <MenuItem value={"q10"}>Question 10</MenuItem>
                  <MenuItem value={"q11"}>Question 11</MenuItem>
                  <MenuItem value={"q12"}>Question 12</MenuItem>
                  <MenuItem value={"q13"}>Question 13</MenuItem>
                  <MenuItem value={"q14"}>Question 14</MenuItem>
                  <MenuItem value={"q15"}>Question 15</MenuItem>
                  <MenuItem value={"q16"}>Question 16</MenuItem>
                  <MenuItem value={"q17"}>Question 17</MenuItem>
                  <MenuItem value={"q18"}>Question 18</MenuItem>
                  <MenuItem value={"q19"}>Question 19</MenuItem>
                  <MenuItem value={"q20"}>Question 20</MenuItem>
                </Select>
              ) : (
                <Select
                  style={{ margin: "30px 0" }}
                  fullWidth
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  label="Age"
                >
                  <MenuItem value={"q1"}>Question 1</MenuItem>
                  <MenuItem value={"q2"}>Question 2</MenuItem>
                  <MenuItem value={"q3"}>Question 3</MenuItem>
                  <MenuItem value={"q4"}>Question 4</MenuItem>
                  <MenuItem value={"q5"}>Question 5</MenuItem>
                  <MenuItem value={"q6"}>Question 6</MenuItem>
                  <MenuItem value={"q7"}>Question 7</MenuItem>
                  <MenuItem value={"q8"}>Question 8</MenuItem>
                  <MenuItem value={"q9"}>Question 9</MenuItem>
                  <MenuItem value={"q10"}>Question 10</MenuItem>
                  <MenuItem value={"q11"}>Question 11</MenuItem>
                  <MenuItem value={"q12"}>Question 12</MenuItem>
                  <MenuItem value={"q13"}>Question 13</MenuItem>
                  <MenuItem value={"q14"}>Question 14</MenuItem>
                  <MenuItem value={"q15"}>Question 15</MenuItem>
                  <MenuItem value={"q16"}>Question 16</MenuItem>
                  <MenuItem value={"q17"}>Question 17</MenuItem>
                  <MenuItem value={"q18"}>Question 18</MenuItem>)
                </Select>
              )
            ) : role === "employer" ? (
              <Select
                style={{ margin: "30px 0" }}
                fullWidth
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              >
                <MenuItem value={"q1"}>Question 1</MenuItem>
                <MenuItem value={"q2"}>Question 2</MenuItem>
                <MenuItem value={"q3"}>Question 3</MenuItem>
                <MenuItem value={"q4"}>Question 4</MenuItem>
                <MenuItem value={"q5"}>Question 5</MenuItem>
                <MenuItem value={"q6"}>Question 6</MenuItem>
                <MenuItem value={"q7"}>Question 7</MenuItem>
                <MenuItem value={"q8"}>Question 8</MenuItem>
                <MenuItem value={"q9"}>Question 9</MenuItem>
                <MenuItem value={"q10"}>Question 10</MenuItem>
                <MenuItem value={"q11"}>Question 11</MenuItem>
                <MenuItem value={"q12"}>Question 12</MenuItem>
                <MenuItem value={"q13"}>Question 13</MenuItem>
                <MenuItem value={"q14"}>Question 14</MenuItem>
                <MenuItem value={"q15"}>Question 15</MenuItem>
                <MenuItem value={"q16"}>Question 16</MenuItem>
                <MenuItem value={"q17"}>Question 17</MenuItem>
                <MenuItem value={"q18"}>Question 18</MenuItem>
                <MenuItem value={"q19"}>Question 19</MenuItem>
                <MenuItem value={"q20"}>Question 20</MenuItem>
                <MenuItem value={"q21"}>Question 21</MenuItem>
                <MenuItem value={"q22"}>Question 22</MenuItem>
                <MenuItem value={"q23"}>Question 23</MenuItem>
                <MenuItem value={"q24"}>Question 24</MenuItem>
                <MenuItem value={"q25"}>Question 25</MenuItem>
                <MenuItem value={"q26"}>Question 26</MenuItem>
              </Select>
            ) : (
              <Select
                style={{ margin: "30px 0" }}
                fullWidth
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                label="Age"
              >
                <MenuItem value={"q1"}>Question 1</MenuItem>
                <MenuItem value={"q2"}>Question 2</MenuItem>
                <MenuItem value={"q3"}>Question 3</MenuItem>
                <MenuItem value={"q4"}>Question 4</MenuItem>
                <MenuItem value={"q5"}>Question 5</MenuItem>
                <MenuItem value={"q6"}>Question 6</MenuItem>
                <MenuItem value={"q7"}>Question 7</MenuItem>
                <MenuItem value={"q8"}>Question 8</MenuItem>
                <MenuItem value={"q9"}>Question 9</MenuItem>
                <MenuItem value={"q10"}>Question 10</MenuItem>
                <MenuItem value={"q11"}>Question 11</MenuItem>
                <MenuItem value={"q12"}>Question 12</MenuItem>
                <MenuItem value={"q13"}>Question 13</MenuItem>
                <MenuItem value={"q14"}>Question 14</MenuItem>
                <MenuItem value={"q15"}>Question 15</MenuItem>
                <MenuItem value={"q16"}>Question 16</MenuItem>
                <MenuItem value={"q17"}>Question 17</MenuItem>
                <MenuItem value={"q18"}>Question 18</MenuItem>
                <MenuItem value={"q19"}>Question 19</MenuItem>
                <MenuItem value={"q20"}>Question 20</MenuItem>
                <MenuItem value={"q21"}>Question 21</MenuItem>
                <MenuItem value={"q22"}>Question 22</MenuItem>
                <MenuItem value={"q23"}>Question 23</MenuItem>
                <MenuItem value={"q24"}>Question 24</MenuItem>
                <MenuItem value={"q25"}>Question 25</MenuItem>
                <MenuItem value={"q26"}>Question 26</MenuItem>
                <MenuItem value={"q27"}>Question 27</MenuItem>
                <MenuItem value={"q28"}>Question 28</MenuItem>
              </Select>
            )}

            <Button variant="contained" onClick={() => splitMyResults()}>
              Get Stats
            </Button>
          </Form> */}
        </>
      )}

      {stats && (
        <ResultsContainer>
          <Question>{statsQuestion}</Question>
          <GraphContainer>
            <ResponsiveContainer
              width={otherResponses.length > 0 ? "50%" : "100%"}
              height={700}
            >
              <PieChart width={1730} height={250}>
                <Pie
                  data={stats}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={"80%"}
                  fill="#4287f5"
                  label
                />
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            {otherResponses.length && (
              <OtherList>
                <h2>Other answers:</h2>
                {otherResponses.map((response: any) => (
                  <ListItem>{response}</ListItem>
                ))}
              </OtherList>
            )}
          </GraphContainer>
        </ResultsContainer>
      )}
    </Layout>
  );
};

export default ResultsPage;

interface IAnswers {
  [key: string]: string | number;
}

import styled from 'styled-components'

export const OtherList = styled.ol`
  font-size: 25px;
  width: 50%;
  margin-top: 50px;
`

export const Question = styled.h2`
width: 100%;
font-size: 23px;
margin: 10px 0;
background: #4287f5;
padding: 10px;
color: white;
border-radius: 3px;
`

export const ListItem = styled.li`
  font-size: 20px;
  margin: 10px 0;
  background: #d1d1d1;
  padding: 4px;
  border-radius: 3px;
`

export const ResultsContainer = styled.li`
  width: 80vw;
  display: flex;
  margin: auto auto;
  flex-direction: column;
  
`
export const GraphContainer = styled.li`
  display: flex;
  flex-direction: row;
  
`
GraphContainer

export const LeftMenu = styled.div`
  position: absolute;
  width: 400px;
  margin: 30px;
  right: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 100;
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
`
export const Form = styled.form`
  width: 400px;
  margin: 30px;
  z-index: 100;
`

export const ModalBackdrop = styled.div<IModalProps>`
  display: ${props => props.active ? "flex" : "none"};
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 2;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  backdrop-filter: blur(2px)
`
export const ModalContainer = styled.div`
  background: rgba(255,255,255,0.75);
  backdrop-filter: blur(2px)  brightness(60%);
  position: absolute;
  width: fit-content;
  max-width: 85vw;
  height: fit-content;
  margin: auto;
  align-self: center;
  border-radius: 15px;
  box-shadow: 0 0 30px 5px rgba(0,0,0,1);
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'Montserrat';
  @media (max-width: 450px) {
    border-radius: 7px;    
  }
`
export const ModalWarning = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 20px;
  @media (max-width: 450px) {
    font-size: 0.75rem;
  }
`


interface IModalProps {
  active: boolean
}